<template>
  <b-card v-if="data" no-body class="mh-100">
    <b-card-header class="border-bottom p-0">
      <div class="d-flex w-100 mx-0 text-center py-0">
        <div
          class="
            d-flex
            w-100
            py-1
            d-flex
            align-items-center
            justify-content-center
          "
        >
          <h4 class="mb-0">{{ $t("modules") }}</h4>
          <br />

          <span class="ml-1"> {{ currentModules }} </span>
        </div>
      </div>
    </b-card-header>

    <b-row class="text-center mx-0">
      <b-col
        cols="6"
        class="
          d-flex
          align-items-between
          flex-column
          py-1
          border-bottom border-right
        "
      >
        <b-card-text class="text-muted mb-0"> {{ $t("total") }}</b-card-text>
        <h5 class="font-weight-bolder mb-0">
          {{ data.leftDays }}
        </h5>
      </b-col>
      <b-col
        cols="6"
        class="border-bottom d-flex align-items-between flex-column py-1"
      >
        <!-- x -->
        <b-card-text class="text-muted mb-0"> {{ $t("endDate") }} </b-card-text>
        <h5 class="font-weight-bolder mb-0">
          {{
            dayjs(data.packageduedate)
              .locale($i18n.locale)
              .format("DD MMMM YYYY")
          }}
        </h5>
      </b-col>
    </b-row>
    <span class="text-muted mt-1"><br /></span>
    <!-- apex chart -->
    <vue-apex-charts
      type="radialBar"
      height="250"
      class="mt-1"
      :options="goalOverviewRadialBar"
      :series="[...data.series[0]]"
    />
    <div
      v-if="!hasEverything"
      class="d-flex justify-content-end mt-auto flex-wrap"
    >
      <router-link
        :to="data.leftDays > 0 ? '/shop' : '/shop-prices'"
        class="my-2 mx-2"
      >
        <b-button class="" variant="outline-danger">
          <feather-icon icon="ShoppingCartIcon" class="" size="20" />
          <span class="align-middle">
            {{
              $t("buy", { type: notExistModules.map((x) => $t(x)).join(", ") })
            }}
          </span>
        </b-button>
      </router-link>
    </div>
    <!-- <div v-if="data.series[0] < 20" class="px-2 h6 text-warning">
      <div class="d-md-flex d-xl-block">
        <br />
        <small> {{ $t("yourCreditsAreLow") }}</small>
      </div>02728002
      02728002
      02728002
      
    </div> -->
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BRow,
  BCol,
  BCardText,
  BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import dayjs from "dayjs";
import tr from "dayjs/locale/tr";
import az from "dayjs/locale/az";
import ru from "dayjs/locale/ru";
import { getUserData } from "@/auth/utils";
const $textHeadingColor = "#5e5873";
const $strokeColor = "#ebe9f1";
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
    BButton,
  },
  props: {
    data: {
      type: Object,
      reqired: true,
      default: () => {},
    },
  },
  data() {
    return {
      tr,
      az,
      ru,
      dayjs,
      userData: getUserData(),
      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: "radialBar",
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [this.data.series[0] >= 20 ? "#51d618" : "#fc0303"],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: "77%",
            },
            track: {
              background: $strokeColor,
              strokeWidth: "50%",
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: "1.5rem",
                fontWeight: "600",
                formatter: (val) =>
                  this.$t("leftDay") + ": " + this.data.leftDays,
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: [
              this.data.series[0] >= 20
                ? $themeColors.success
                : $themeColors.primary,
            ],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
    };
  },
  computed: {
    hasEverything() {
      const { hasdairy, hasbeef, hassheep, hasgoat } = this.data;
      return hasdairy && hasbeef && hassheep && hasgoat;
    },
    notExistModules() {
      const { hasdairy, hasbeef, hassheep, hasgoat } = this.data;
      var modules = [];
      if (!hasdairy) {
        modules.push("dairy");
      }
      if (!hasbeef) {
        modules.push("beef");
      }
      if (!hassheep) {
        modules.push("sheep");
      }
      if (!hasgoat) {
        modules.push("goat");
      }
      return modules;
    },
    currentModules() {
      const { hasdairy, hasbeef, hassheep, hasgoat } = this.data;
      var modules = [];
      if (hasdairy) {
        modules.push(this.$t("dairy"));
      }
      if (hasbeef) {
        modules.push(this.$t("beef"));
      }
      if (hassheep) {
        modules.push(this.$t("sheep"));
      }
      if (hasgoat) {
        modules.push(this.$t("goat"));
      }
      return modules.join(", ");
    },
  },
};
</script>
<style scoped>
.mh-100 {
  min-height: 100%;
}
</style>
