<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <b-row v-if="isLoggedIn && credits">
      <b-col
        v-if="credits.leftDays != 0 && credits.leftDays < 15"
        class="mb-2"
        cols="12"
      >
        <div class="demo-spacing-0">
          <b-alert variant="danger" show>
            <div class="alert-body">
              <div class="alert-title">
                <h5>
                  <i class="fas fa-exclamation-triangle"></i>
                  <span>{{ $t("warning") }}</span>
                </h5>
              </div>
              <div class="alert-message text-white">
                <p>
                  <span
                    v-if="Math.abs(credits.leftDays) + credits.leftDays != 0"
                  >
                    <span
                      v-html="
                        $t('leftDateMessage', { leftDays: credits.leftDays })
                      "
                    ></span>

                    <a class="text-success" @click="checkout(3)">
                      <b>{{ $t("yearly") }}</b>
                    </a>
                    {{ $t("or") }}
                    <a class="text-success" @click="checkout(2)">
                      <b>{{ $t("monthly") }}</b>
                    </a>
                    <span v-if="$i18n.locale === 'tr'">
                      paketlerinden birini satın alabilirsiniz.
                    </span>
                  </span>
                  <span
                    v-else
                    class="d-flex justify-content-start align-items-center"
                  >
                    {{ $t("endDateMessage") }}
                    <a
                      class="text-success btn btn-success m-2"
                      @click="checkout(3)"
                    >
                      <b>{{ $t("buyNow") }}</b>
                    </a>
                    <!-- veya
                    <a class="text-success" @click="checkout(2)">
                      <b>{{ $t("monthly") }}</b>
                    </a> -->
                  </span>
                </p>
              </div>
            </div>
          </b-alert>
        </div>
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="3" xl="3" class="pb-2">
        <company-credits
          v-if="credits.series"
          :data="credits"
        ></company-credits>
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="3" xl="3" class="pb-2">
        <left-chart
          v-if="credits.series"
          :left-count="credits.leftformula"
          :max-count="credits.maxformula"
          :title="$t('rations')"
          :series="credits.series[1]"
          :to-link="credits.leftDays > 0 ? '/shop' : '/shop-prices'"
        ></left-chart>
      </b-col>
      <b-col cols="12" sm="6" md="6" lg="3" xl="3" class="pb-2">
        <left-chart
          v-if="credits.series"
          :left-count="credits.leftplant"
          :max-count="credits.maxplant"
          :title="$t('plants')"
          :series="credits.series[2]"
          :to-link="credits.leftDays > 0 ? '/shop' : '/shop-prices'"
        ></left-chart>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" sm="12">
        <!-- <company-info :data="companyData"></company-info> -->
      </b-col>
    </b-row>
    <b-modal
      id="checkout"
      ref="checkoutModal"
      :title="$t('nutrientMatrix')"
      size="md"
      :ok-title="$t('ok')"
      ok-only
    >
      <b-card no-body class="min-h-100">
        <div class="mh-full">
          <iframe
            v-if="token"
            id="paytriframe"
            :src="'https://www.paytr.com/odeme/guvenli/' + token"
            frameborder="0"
            scrolling="true"
            style="width: 100%"
          ></iframe>
        </div>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt";
import CompanyInfo from "./Dashboard/CompanyInfo.vue";
import { BRow, BCol, BAlert, BCard, BModal } from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
import { initialAbility } from "@/libs/acl/config";
import CompanyCredits from "@/views/charts/CompanyCredits.vue";
import LeftChart from "@/views/charts/LeftChart.vue";
import { credit } from "@/@core/utils/validations/validations";
export default {
  components: {
    BRow,
    BCol,
    BAlert,
    // CompanyInfo,
    BModal,
    BCard,

    // UserViewUserTimelineCard,
    CompanyCredits,
    LeftChart,
  },
  data: function () {
    return {
      companyData: getUserData(),
      isLoggedIn: true,
      credits: {},
      ilikstore: "https://ilikstore.com",
      token: null,
    };
  },

  async mounted() {
    var credits = await this.$store.dispatch(
      "app/fetchCompanyCredits",
      this.companyData.companyid
    );
    this.credits = credits?.[0];

    if (getUserData().packageexpired && this.credits.leftDays > 0) {
      this.logout();
      this.isLoggedIn = false;
      this.$toast(this.$t("packageRenewed"));
    }
    if (this.credits) {
      this.credits.leftDays = this.credits.leftDays ?? 0;
      var mainPercent = (
        (parseFloat(this.credits.leftDays) / parseFloat(365)) *
        100
      ).toFixed(1);
      var secondaryPercent = (
        (parseFloat(
          this.credits.leftformula < 0 ? 0 : this.credits.leftformula
        ) /
          parseFloat(this.credits.maxformula)) *
        100
      ).toFixed(1);
      var thirdPercent = (
        (parseFloat(this.credits.leftplant < 0 ? 0 : this.credits.leftplant) /
          parseFloat(this.credits.maxplant)) *
        100
      ).toFixed(1);
      var fourthPercent = (
        (parseFloat(this.credits.leftuser < 0 ? 0 : this.credits.leftuser) /
          parseFloat(this.credits.maxuser)) *
        100
      ).toFixed(1);
      this.credits.series = [
        isNaN(mainPercent) ? 0 : mainPercent,
        isNaN(secondaryPercent) ? 0 : secondaryPercent,
        isNaN(thirdPercent) ? 0 : thirdPercent,
        isNaN(fourthPercent) ? 0 : fourthPercent,
      ];
    } else {
      this.credits = {
        series: [1, 1, 1, 1],
        leftDays: 0,
        leftformula: 0,
        leftplant: 0,
        leftuser: 0,
        maxformula: 0,
        maxplant: 0,
        maxuser: 0,
      };
    }
    if (getUserData() == null) {
      this.logout();
      this.isLoggedIn = false;
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage.
      // If you like, you can also make API call to
      // backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
      // Remove userData from localStorage
      localStorage.removeItem("userData");
      // Reset ability
      this.$ability.update(initialAbility);
      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },
    async checkout(priceType) {
      var { data } = await this.$http.get("/Payment/getrenewiframetoken", {
        headers: {
          pricetype: priceType,
        },
      });
      this.token = data.statusdata;
      this.$refs.checkoutModal.show();
    },
  },
};
</script>
<style lang="scss">
.mh-full {
  @media (max-width: 479px) {
    height: 150vh !important;
  }

  height: 129vh !important;
}
#paytriframe {
  min-height: 100% !important;
  overflow: scroll;
}
#paytriframe body {
  min-height: 100% !important;
  overflow: scroll;
}
#paytriframe form {
  overflow: scroll !important;
}
</style>
